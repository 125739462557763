import React from 'react';

export const DownArrowIcon = () => {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.63605 5.29279C1.82358 5.10532 2.07788 5 2.34305 5C2.60821 5 2.86252 5.10532 3.05005 5.29279L8.00005 10.2428L12.95 5.29279C13.1387 5.11063 13.3913 5.00983 13.6535 5.01211C13.9156 5.01439 14.1665 5.11956 14.3519 5.30497C14.5373 5.49038 14.6424 5.74119 14.6447 6.00339C14.647 6.26558 14.5462 6.51818 14.364 6.70679L8.70705 12.3638C8.51952 12.5513 8.26521 12.6566 8.00005 12.6566C7.73488 12.6566 7.48058 12.5513 7.29305 12.3638L1.63605 6.70679C1.44858 6.51926 1.34326 6.26495 1.34326 5.99979C1.34326 5.73462 1.44858 5.48031 1.63605 5.29279Z"
        fill="#F89A21"
      />
    </svg>
  );
};
