import { getNativeUtils } from '@utils/nativeUtils';
import SweetAlert from '../sweetAlert';

interface IEkycPopup {
  description: string;
  closePopup: (param: boolean) => void;
  showPopup: boolean;
}
const EkycPopup = (props: IEkycPopup) => {
  const { description, closePopup, showPopup } = props;
  return (
    <SweetAlert
      buttons={[
        {
          title: 'Start KYC',
          callback: () => {
            const redirectionUrl = `${process.env.NEXT_PUBLIC_WEBVIEW_URL}e-kyc/select`;
            closePopup(false);
            getNativeUtils()?.openWebview(redirectionUrl, 'e-kyc');
          },
          buttonClassName:
            'bg-primary tw-rounded-sm tw-px-2 tw-py-1 tw-text-[12px] tw-text-white tw-bg-[#5FA744] tw-h-8 tw-w-24',
        },
        {
          title: 'Skip for now',
          callback: () => {
            closePopup(false);
          },
          buttonClassName:
            'tw-px-2 tw-py-1 tw-text-[12px] tw-text-[#5FA744] tw-h-8 tw-w-24 tw-ml-4 tw-text-bold tw-border tw-border-[#5FA744]',
        },
      ]}
      closeIcon={{
        show: true,
        callback: () => {
          closePopup(false);
        },
      }}
      description={description}
      descriptionCustomClass="tw-font-normal tw-text-[12px]"
      height="100px"
      icon={false}
      showModal={showPopup}
      title=""
      variant="v2"
    />
  );
};
export default EkycPopup;
