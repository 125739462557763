import React, { useMemo, useState } from 'react';

// any values added here must be cleared on logout
interface IGlobalContextProps {
  loading: boolean;
  setLoading: Function;
  isHamburgerOpen: boolean;
  toggleHamBurger: Function;
  transactionDetail: Object;
  setTransactionDetail: Function;
  isShowBalanceClicked: boolean;
  setShowBalanceClicked: Function;
  showProfilePopup: boolean;
  setShowProfilePopup: Function;
  showPgFees: boolean;
  setShowPgFees: Function;
  withdrawPopup: boolean;
  setWithdrawPopup: Function;
  selectedFooter: string;
  setSelectedFooter: Function;
  paymentDetail: Object;
  setPaymentDetail: Function;
  showQrModal: boolean;
  setShowQrModal: Function;
  smsLimitSet: boolean;
  setSmsLimitSet: Function;
  showKiranaSellerBS: boolean;
  setShowKiranaSellerBS: Function;
  ntcEnabled: boolean;
  setNtcEnabled: Function;
}

const initalContext = {
  loading: false,
  setLoading: () => {},
  isHamburgerOpen: false,
  toggleHamBurger: () => {},
  transactionDetail: {},
  setTransactionDetail: () => {},
  isShowBalanceClicked: false,
  setShowBalanceClicked: () => {},
  showProfilePopup: false,
  setShowProfilePopup: () => {},
  showPgFees: false,
  setShowPgFees: () => {},
  withdrawPopup: false,
  setWithdrawPopup: () => {},
  setSelectedFooter: () => {},
  selectedFooter: 'Home',
  setPaymentDetail: () => {},
  smsLimitSet: false,
  setSmsLimitSet: () => {},
  showKiranaSellerBS: false,
  setShowKiranaSellerBS: () => {},
  ntcEnabled: false,
  setNtcEnabled: () => {},
};

export const GlobalContext =
  React.createContext<IGlobalContextProps>(initalContext);

export function GlobalContextProvider(props: any) {
  const { children } = props;
  const [showQrModal, setShowQrModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isHamburgerOpen, toggleHamBurger] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState({});
  const [isShowBalanceClicked, setShowBalanceClicked] = useState(false);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [showPgFees, setShowPgFees] = useState(false);
  const [withdrawPopup, setWithdrawPopup] = useState(false);
  const [selectedFooter, setSelectedFooter] = useState('Home');
  const [paymentDetail, setPaymentDetail] = useState({});
  const [smsLimitSet, setSmsLimitSet] = useState(false);
  const [showKiranaSellerBS, setShowKiranaSellerBS] = useState(false);
  const [ntcEnabled, setNtcEnabled] = useState(false);

  const value = useMemo(
    () => ({
      loading,
      setLoading,
      toggleHamBurger,
      isHamburgerOpen,
      transactionDetail,
      setTransactionDetail,
      setShowBalanceClicked,
      isShowBalanceClicked,
      setShowProfilePopup,
      showProfilePopup,
      showPgFees,
      setShowPgFees,
      withdrawPopup,
      setWithdrawPopup,
      setSelectedFooter,
      selectedFooter,
      paymentDetail,
      setPaymentDetail,
      showQrModal,
      setShowQrModal,
      smsLimitSet,
      setSmsLimitSet,
      showKiranaSellerBS,
      setShowKiranaSellerBS,
      ntcEnabled,
      setNtcEnabled,
    }),
    [
      isHamburgerOpen,
      loading,
      transactionDetail,
      isShowBalanceClicked,
      showProfilePopup,
      showPgFees,
      withdrawPopup,
      selectedFooter,
      paymentDetail,
      showQrModal,
      smsLimitSet,
      showKiranaSellerBS,
      ntcEnabled,
    ]
  );

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}
