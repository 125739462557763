import { apiClient } from '@components/api/apiClient';
import paths from '@components/api/paths';
import {
  setKycLiteStatus,
  setKycStatus,
  updatePaymentConfig,
} from '@utils/commonUtils';
import { QueryKeys, UserTypeID } from 'constants/common';
import { IRequestMeta, QueryProps } from 'constants/types';
import { useQuery } from 'react-query';
import { KycUserActionSummary } from 'types/ekyc.types';

interface IResponseData extends IRequestMeta {
  data: KycUserActionSummary[];
}

async function fetchKycSummary(
  realmId?: string,
  userId?: number,
  userTypeId?: number
): Promise<KycUserActionSummary | undefined> {
  try {
    if (realmId && userId) {
      const url = paths.getKycSummaryUrl(realmId, userId);
      const body = {
        userTypeId,
      };
      const response: IResponseData = await apiClient.post(url, body);
      userTypeId === UserTypeID.KYC
        ? setKycStatus(response.data[0])
        : setKycLiteStatus(response.data[0]);
      updatePaymentConfig();
      return response.data[0];
    }
  } catch (error: any) {
    return Promise.reject(error);
  }
}

const useKycSummary = (
  realmId?: string,
  userId?: number,
  userTypeId?: number,
  options?: QueryProps
) => {
  return useQuery(
    [QueryKeys.kycSummary, userTypeId],
    () => fetchKycSummary(realmId, userId, userTypeId),
    {
      ...options,
    }
  );
};

export { useKycSummary };
