/* eslint-disable */
import * as React from 'react';
import Image from 'next/image';
import redAlert from 'assets/red-alert.png';
import { NCIcon } from '@ninjacart/nc-components-ui';

function SweetAlert(props) {
  const {
    showModal,
    title,
    description,
    buttons,
    variant = 'v2',
    buttonType = 'filled',
    icon,
    descriptionCustomClass,
    height,
    closeIcon,
  }: {
    showModal: boolean;
    title: string;
    description: string;
    buttons?: any;
    variant?: string;
    buttonType?: string;
    icon: boolean;
    descriptionCustomClass?: string;
    height?: string;
    closeIcon?: any;
  } = props;

  let headerClassName = 'flex items-start justify-between';
  if (variant === 'v2') {
    headerClassName = '';
  }

  let bodyClassName = 'font-light leading-5 text-left text-sm';
  if (variant === 'v2') {
    bodyClassName = 'font-light leading-5 text-center text-base';
  }

  let footerClassName = 'flex items-center justify-end';
  if (variant === 'v2') {
    footerClassName = 'flex items-center justify-center';
  }

  let buttonClassName =
    'text-[#5FA744] background-transparent focus:outline-none px-3 py-1 mb-1 mr-1 font-bold transition-all duration-150 ease-linear outline-none border rounded-lg border-primary tw-primary';
  if (buttonType === 'filled') {
    buttonClassName =
      'tw-h-[40px] bg-[#5FA744] rounded-lg text-white focus:outline-none px-3 py-1 mb-1 mr-1 transition-all duration-150 ease-linear outline-none';
  }
  let descriptionClassNameDefault = 'tw-l-5; tw-text-lg tw-font-light';

  return (
    <>
      {showModal ? (
        <>
          <div className="focus:outline-none fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none">
            <div className="desktop:w-4/12 tablet:w-3/6 relative w-5/6 mx-auto my-6">
              {closeIcon?.show && (
                <span onClick={closeIcon.callback}>
                  <NCIcon
                    className="tw-absolute tw-right-[5px] tw-top-[5px] tw-z-10 tw-h-4 tw-w-4"
                    color="grey"
                    iconName="cross"
                  />
                </span>
              )}
              {/* content */}
              <div
                className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-4"
                style={{ minHeight: height ? height : '200px' }}
              >
                {/* header */}
                {icon && (
                  <div className="tw-flex tw-items-center tw-justify-center">
                    <Image src={redAlert} alt="" width={60} height={60} />
                  </div>
                )}
                <div className={headerClassName}>
                  <h3 className="text-xl font-extrabold">{title}</h3>
                </div>
                {/* body */}
                <div className="relative flex-auto py-4 tw-flex tw-items-center tw-justify-center">
                  <p
                    className={
                      descriptionCustomClass || descriptionClassNameDefault
                    }
                  >
                    {description}
                  </p>
                </div>
                {/* footer */}
                <div className={footerClassName}>
                  {buttons.map((item, key) => {
                    if (variant === 'v1') {
                      return (
                        <button
                          key={`${item.title}${key}`}
                          className="text-primary background-transparent focus:outline-none px-2 py-2 mb-1 mr-1 text-sm font-bold uppercase transition-all duration-150 ease-linear outline-none"
                          type="button"
                          onClick={item.callback}
                        >
                          {item.title}
                        </button>
                      );
                    }
                    if (variant === 'v2') {
                      return (
                        <button
                          key={`${item.title}${key}`}
                          className={item.buttonClassName || buttonClassName}
                          type="button"
                          onClick={item.callback}
                        >
                          {item.title}
                        </button>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 backdrop-blur-sm bg-black/30 opacity-50" />
        </>
      ) : null}
    </>
  );
}

export default SweetAlert;
