import { getAuthData } from '@utils/storage';
import axios, {
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import Router from 'next/router';

const ROUTE_WITHOUT_TOKEN = [
  'user-login-otp-notification-service-config',
  'runWithNoAuth/onboard-traders',
  'runWithNoAuth/old-app-events-push',
];

/**
 * use apiClient to enable interceptors defined in useAxiosInterceptor.ts
 */
export const apiClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'x-nc-tool-id': process.env.NEXT_PUBLIC_TOOL_ID,
  },
});

apiClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    const authRoutes = ROUTE_WITHOUT_TOKEN.some((i) =>
      config?.url?.includes(i)
    );
    const { token_type, token } = getAuthData();
    if (!authRoutes && token && !config.headers.Authorization) {
      config.headers.Authorization = `${token_type} ${token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// Remove extra data from request
apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data.error) {
      return Promise.reject(response.data);
    }
    return Promise.resolve(response.data);
  },
  (error) => {
    // logout if session expired
    if (error?.response?.status === 401) {
      Router.push('/logout');
      return;
    }

    // Reject promise if usual erro
    return Promise.reject(error);
  }
);
