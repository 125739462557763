import { TraderEventType } from '@utils/eventsConstant';
import { STORAGE_KEYS } from '@utils/keys';
import useEvents from 'hook/useEvents';
import useStorage from 'hook/useStorage';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { DashboardIcon } from 'types/DashboardTypes';
import Image from 'next/image';
import { onVisible } from '@utils/Utils';

interface DashboardIconProps extends DashboardIcon {
  onClick: (url: string, menuName: string) => void;
  featured?: boolean;
}

export const DashboardIconNew = (props: DashboardIconProps) => {
  const {
    newFeature,
    menuName,
    url,
    id,
    onClick,
    name,
    featured,
    elementId,
    icon,
  } = props;

  const { getItem } = useStorage();
  const { triggerEvent } = useEvents();

  const checkMenuLoaded = () => {
    if (menuName === 'Mandi News') {
      onVisible(document.querySelector(`#${elementId}`), () => {
        const additionalDetails = [
          {
            refType: 'trader_name',
            refValue: getItem(STORAGE_KEYS.REALM_OWNER_DATA)?.realmName,
          },
          {
            refType: 'AsgardId',
            refValue: JSON.stringify(
              getItem(STORAGE_KEYS.REALM_OWNER_DATA)?.asgardUserId
            ),
          },
          {
            refType: 'app_version',
            refValue: getItem(STORAGE_KEYS.APP_BUILD_VERSION) || 'Browser',
          },
          {
            refType: 'visit_date',
            refValue: JSON.stringify(new Date().getTime()),
          },
        ];
        triggerEvent(TraderEventType.Generic_event, {
          eventName: TraderEventType.MandiNews_Icon_Viewed,
          additionalDetails,
        });
      });
    }
  };

  return (
    <div
      key={id}
      className={`flex-col justify-center items-center relative tw-m-[5px] tw-flex tw-rounded-xl tw-p-2 ${
        featured
          ? 'tw-bg-ninja-primary-300'
          : 'tw-border-ninja-gray-border tw-border-[0.5px] tw-bg-[#FAFAFA]'
      }`}
      style={{
        height: '76px',
        flex: '0 0 calc(33.33% - 10px)',
        maxWidth: 'calc(33.33% - 10px)',
      }}
      onClick={() => onClick(url || '', menuName)}
    >
      {newFeature ? (
        <div
          className="bodyLabel tw-absolute tw-top-[-7px] tw-rounded-xl tw-px-1 tw-font-medium tw-text-white"
          style={{
            background: 'linear-gradient(180deg, #FFE000 0%, #FF8F00 100%)',
          }}
        >
          New
        </div>
      ) : null}

      <Image
        alt=""
        id={elementId}
        src={icon}
        onLoad={() => (checkMenuLoaded ? checkMenuLoaded() : {})}
      />
      <div
        className={'bodyLabel tw-font-medium tw-text-blackText'}
        style={{
          width: '90%',
          textAlign: 'center',
          overflowWrap: 'break-word',
          lineHeight: '15px',
        }}
      >
        {name}{' '}
      </div>
    </div>
  );
};

interface IProps {
  list: DashboardIcon[];
  onClick: (url: string, menuName: string) => {};
}

// TODO: Make DashboardIcon as renderProps
function DashboardIconListNew({ list, onClick }: IProps) {
  return (
    <div className="tw-flex tw-flex-wrap tw-px-[11px]">
      {!isEmpty(list) &&
        list.map((item) => (
          <DashboardIconNew {...item} key={item.menuName} onClick={onClick} />
        ))}
    </div>
  );
}

export default DashboardIconListNew;
