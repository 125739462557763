export const ROUTE_NAMES = {
  INTRO: '/intro',
  INTRO_ONE: '/intro-one',
  INTRO_TWO: '/intro-two',
  INTRO_THREE: '/intro-three',
  LOGIN: '/login',
  AGREEMENT: '/agreement',
  OTP: '/otp',
  CAPATURE_BUSINESS: '/capture-business',
  EKYC_SPLASH: '/ekyc-splash',
  PARTIES: '/parties',
  EKYC_SUBMIT_INFO: '/e-kyc/submit-info',
  EKYC_LITE: '/e-kyc/kyc-lite',
  DASHBOARD: '/dashboard',
  SWITCH_BUSINESS: '/switch-business',
  TRANSACTION_HISTORY: '/transaction-history',
  TRANSACTION_DETAIL: '/transaction-detail',
  HELP: '/help',
  PROFILE: '/profile',
  PROFILE_COVER_IMAGE_UPLOAD: '/profile/cover-image-upload',
  EDIT_PROFILE: '/profile/edit-profile',
  BANK_DETAILS: 'new-profile',
  NOTIFICAION: '/notification',
  TEAM: 'app/content/team',
  CREDIT_SCODE: 'credit-score',
  LANGUAGE: '/language',
  TERMSCONDITION: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  LOGOUT: '/logout',
  SETTINGS: 'app/content/settings',
  PG_ABOUT_ASSURED: '/payment-guarantee/about-payment-assured',
  PG_DETAILS: '/payment-guarantee/details',
  PG_APPLY_PRODUCT: '/payment-guarantee/choose-product',
  PG_SUPPORT: '/payment-guarantee/support',
  INSTA_OD_ABOUT: '/insta-od-about',
  CONTRACTS: '/contracts',
  CONSENT: '/consent',
  PARTY_ACTIVITY: '/party-activity',
  ACCOUNT_STATEMENT: '/account-statement',
  PAYMENT: '/party-activity/payment',
  PAY_MONEY: '/party-activity/pay-money',
  PAY_MONEY_NG: 'app/content/pay-money',
  LINK_ACCOUNT: '/link-account',
  LINK_ACCOUNT_BANK_DETAILS: '/link-account/bank-details',
  ADD_MONEY: '/add-money',
  NEFT_PAYMENT: '/add-money/neft-payment',
  UPI_PAYMENT: '/add-money/upi',
  PAY_MONEY_OTP: '/party-activity/pay-money/otp',
  NSPL_PRIVACY_POLICY: '/nspl-terms-privacy',
  SHORT_FORM: '/short-form',
  CONSENT_EKYC: '/consent-ekyc',
  VERIFY_ADDRESS_OTP: '/verify-address-otp',
  NINJA_ACCOUNT: '/ninja-account',
  CONSENT_SMS: '/consent-sms',
  KIRANA_SELLER: '/ninjapay-transaction',
  AUTO_PAY: '/auto-pay',
  KIRANA_REFERRAL: '/refer',
  RETURN_PE_REWARD: '/return-pe-reward',
  EARN_BIG: '/earn-with-ninja-kirana',
  SUPER_SELLER: '/super-seller',
  EARNINGS_DETAILS: '/earnings-details',
  RETAILER_CONSENT: '/retailer-consent',
};
