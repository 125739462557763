import * as React from 'react';
import { NCIcon } from '@ninjacart/nc-components-ui';
import Image from 'next/image';
import notification from 'assets/notification_icon.svg';
import help from 'assets/help_icon.svg';
import qrcode from 'assets/dashIcons/qrcode.png';
import { redirectToPage } from 'utils/nativeUtils';
import { KYC_STATUS, iconSize } from 'constants/common';
import useEvents from 'hook/useEvents';
import { TraderEventType } from '@utils/eventsConstant';
import { useRouter } from 'next/router';
import { getStorageItem } from '@utils/storage';
import { STORAGE_KEYS } from '@utils/keys';
import EkycPopup from '../ekyc-popup';
import { ReactNode } from 'react';
import bellIcon from 'assets/dashboardIcons/bell.svg';
import helpIcon from 'assets/dashboardIcons/call.svg';

interface PropTypes {
  className?: string | null;
  isBackButton?: boolean;
  onBackClick?: () => void;
  leftIcon?: React.ReactElement | null;
  showDownload?: boolean;
  headerTitle: string | null | ReactNode;
  downloadAction?: React.MouseEventHandler;
  showNotification?: boolean;
  showHelp?: boolean;
  titleClassNames?: string;
  showQr?: boolean;
  partyQrData?: any;
  isNew?: boolean;
  backButtonColor?: string
}

const Header = React.memo((props: PropTypes) => {
  const { triggerEvent } = useEvents();

  const router = useRouter();
  const kycStatus = getStorageItem(STORAGE_KEYS.KYC_STATUS);
  const [showKycBlocker, setShowKycBlocker] = React.useState(false);
  return (
    <div
      className={`tw-fixed tw-top-0 tw-z-10 tw-flex tw-w-full tw-content-between tw-items-center tw-px-3 
      ${
        props.isNew
          ? 'tw-bg-white tw-py-2 tw-text-black tw-shadow-[0px_4px_25px_0px_rgba(0,0,0,0.1)]'
          : 'bg-primary tw-h-[48px]'
      } ${props.className || ''}`}
    >
      {props.isBackButton && (
        <div className="tw-flex tw-h-full tw-min-w-max">
          <button onClick={props.onBackClick}>
            <NCIcon
              color={props.backButtonColor || 'white'}
              height={iconSize - 2}
              iconName="chevron_left"
              width={iconSize - 2}
            />
          </button>
        </div>
      )}
      {props.leftIcon || null}
      <div
        className={` flex-1 tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-2 ${
          props.isNew ? 'tw-text-black' : 'tw-text-white'
        }  tw-text-xl tw-font-medium  ${props.titleClassNames}`}
      >
        {props.headerTitle}
      </div>
      <div className="tw-flex tw-gap-2">
        {props.showDownload && (
          <div onClick={props.downloadAction}>
            <NCIcon
              className="tw-relative tw-mr-6"
              color="white"
              height={iconSize - 2}
              iconName="download"
              width={iconSize - 2}
            />
          </div>
        )}
        {props.showQr && (
          <div>
            <Image
              alt=""
              className="tw-relative tw-right-3"
              height={iconSize - 2}
              src={qrcode}
              width={iconSize - 2}
              onClick={() => {
                const redirectionUrl = `${process.env.NEXT_PUBLIC_WEBVIEW_URL}qrCode`;
                if (kycStatus !== KYC_STATUS.VERIFIED) {
                  setShowKycBlocker(true);
                } else {
                  redirectToPage(
                    redirectionUrl,
                    'qrcode',
                    JSON.stringify(props.partyQrData)?.replace(/"/g, "'")
                  );
                }
              }}
            />
          </div>
        )}
        {props.showNotification && (
          <Image
            alt=""
            className="tw-right-3"
            height={props.isNew ? 40 : iconSize - 2}
            src={props.isNew ? bellIcon : notification}
            width={props.isNew ? 40 : iconSize - 2}
            onClick={() => {
              triggerEvent(TraderEventType.Generic_event, {
                eventName: 'notification_bell_select',
              });
              router.push('/notification');
            }}
          />
        )}
        {props.showHelp && (
          <Image
            alt=""
            className="tw-right-3 tw-ml-2"
            height={props.isNew ? 40 : iconSize - 2}
            src={props.isNew ? helpIcon : help}
            width={props.isNew ? 40 : iconSize - 2}
            onClick={() => {
              triggerEvent(TraderEventType.Generic_event, {
                eventName: 'Header_help_initate',
              });
              router.push('/help');
            }}
          />
        )}
      </div>
      <EkycPopup
        closePopup={() => setShowKycBlocker(false)}
        description={'⚠ Please complete KYC to collect payments'}
        showPopup={showKycBlocker}
      />
    </div>
  );
});

export default Header;
