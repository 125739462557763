function Exclamation(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 16 16"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.005 3.1a1 1 0 111.99 0l-.388 6.35a.61.61 0 01-1.214 0L7.005 3.1zM7 12a1 1 0 112 0 1 1 0 01-2 0z"
        fill={props.color}
      ></path>
    </svg>
  );
}

export default Exclamation;
