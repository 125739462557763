import {
  KYC_LITE_STATUS,
  KYC_STATUS,
  SUPPORT_MOBILE_NUMBER,
} from 'constants/common';
import { KycUserActionSummary } from 'types/ekyc.types';
import { getNativeUtils } from 'utils/nativeUtils';
import { STORAGE_KEYS } from './keys';
import { getStorageItem, setStorageItem } from './storage';

export function contactSupport() {
  try {
    if (getNativeUtils()) {
      getNativeUtils()?.dialPhoneNumber(SUPPORT_MOBILE_NUMBER);
    } else {
      window.location.href = `tel:${SUPPORT_MOBILE_NUMBER}`;
    }
  } catch (e) {
    console.error('----->handleCall  e', e);
  }
}

export function setKycStatus({
  actionSummary,
  isTicketPending,
  isReupload,
}: KycUserActionSummary) {
  setStorageItem(STORAGE_KEYS.KYC_REUPLOAD, false);

  if (!actionSummary?.length) {
    setStorageItem(STORAGE_KEYS.KYC_STATUS, KYC_STATUS.PENDING);
    return;
  }

  if (isTicketPending) {
    setStorageItem(STORAGE_KEYS.KYC_STATUS, KYC_STATUS.PROGRESS);
    return;
  }

  const isVerified = actionSummary.some((m) => m.status === 'ALLOWED');
  if (isVerified) {
    setStorageItem(STORAGE_KEYS.KYC_STATUS, KYC_STATUS.VERIFIED);
    return;
  }

  if (isReupload) {
    setStorageItem(STORAGE_KEYS.KYC_STATUS, KYC_STATUS.PROGRESS);
    setStorageItem(STORAGE_KEYS.KYC_REUPLOAD, true);
    return;
  }

  setStorageItem(STORAGE_KEYS.KYC_STATUS, KYC_STATUS.PENDING);
}

export function setKycLiteStatus({
  actionSummary,
  isTicketPending,
  isReupload,
}: KycUserActionSummary) {
  if (!actionSummary?.length) {
    setStorageItem(STORAGE_KEYS.KYC_LITE_STATUS, KYC_LITE_STATUS.PENDING);
    return;
  }

  if (isTicketPending) {
    setStorageItem(STORAGE_KEYS.KYC_LITE_STATUS, KYC_LITE_STATUS.PROGRESS);
    return;
  }

  const isVerified = actionSummary.some((m) => m.status === 'ALLOWED');
  if (isVerified) {
    setStorageItem(STORAGE_KEYS.KYC_LITE_STATUS, KYC_LITE_STATUS.VERIFIED);
    return;
  }

  if (isReupload) {
    setStorageItem(STORAGE_KEYS.KYC_LITE_STATUS, KYC_LITE_STATUS.PENDING);
    return;
  }

  setStorageItem(STORAGE_KEYS.KYC_LITE_STATUS, KYC_LITE_STATUS.PENDING);
}

export function updatePaymentConfig() {
  //Set kyc status for qr sdk
  let paymentConfig: any = getStorageItem(STORAGE_KEYS.PAYMENT_CONFIG);

  // set userRealmId
  const { userRealmId } = getStorageItem(STORAGE_KEYS.REALM_OWNER_DATA) || {};

  if (paymentConfig) {
    paymentConfig = {
      ...paymentConfig,
      userRealmId: Number(userRealmId),
      kycStatus: getStorageItem(STORAGE_KEYS.KYC_STATUS),
      kycLiteStatus: getStorageItem(STORAGE_KEYS.KYC_LITE_STATUS),
    };
    setStorageItem(STORAGE_KEYS.PAYMENT_CONFIG, paymentConfig);
  }
}

export function updatePaymentConfigforAndroidVersion() {
  //Set android version for qr sdk
  let paymentConfig: any = getStorageItem(STORAGE_KEYS.PAYMENT_CONFIG);

  if (paymentConfig) {
    paymentConfig = {
      ...paymentConfig,
      appBuildVersion: getStorageItem(STORAGE_KEYS.APP_BUILD_VERSION),
      isAndroid: getStorageItem(STORAGE_KEYS.IS_ANDROID),
    };
    setStorageItem(STORAGE_KEYS.PAYMENT_CONFIG, paymentConfig);
  }
}

export function convertToMonthYear(dateCode) {
  const year = Math.floor(dateCode / 100);
  const month = (dateCode % 100) - 1;
  return new Date(year, month).toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  });
}
