export enum SplitIo {
  AddMoney = 'Add_money',
  PayCollect = 'pay_collect',
  AddContract = 'add_contract',
  LoanHpIcon = 'loans_hp_icon',
  ScanPay = 'scan_pay',
  MoneyTransferHeading = 'money_transfer_heading',
  CommerceHeading = 'commerce_heading',
  OfferingsHeading = 'offerings_heading',
  HelloHeading = 'hello_heading',
  CommerceHpFooter = 'commerce_hp_footer',
  PartiesHpFooter = 'parties_hp_footer',
  LoansHpFooter = 'loans_hp_footer',
  TransactionHpFooter = 'transaction_hp_footer',
  HomeIconFooter = 'homeicon_footer',
  NotificationHamburger = 'notification_hamburger',
  ShowBalanceFirstBanner = 'Show_balance_hp_banner',
  MoneyTransferBanner = 'money_transfer_hp_banner',
  CommerceBanner = 'commerce_hp_banner',
  OfferingsBanner = 'offerings_hp_banner',
  CollectAssure = 'Collect_assure',
  PaymentAssurance = 'payment_assure',
  Rewards = 'rewards_hp',
  ReferAndEarn = 'refer_earn',
  Offers = 'offers_hp',
  AddSellingTrade = 'add_selling_trade',
  AddRequirement = 'add_req',
  ExploreBusiness = 'explore_business',
  COLLECT_PLUS = 'CollectPlus',
  GENERIC_PAY = 'GenericPay',
  MULTI_BANK = 'multibank',
  ConnectedBanking = 'ConnectedBanking',
  CollectAssureCPV = 'CollectAssureCPV',
  MultiBanking = 'MultiBanking',
  PAY_LATER = 'PayLater',
  KnowYourCibilScore = 'KnowYourCibilScore',
  DefaultersSegment = 'Defaulter',
  PrimaryVanTxnTagging = 'PrimaryVanTxnTagging',
  CBAccountUnLink = 'CBAccountUnLink',
  FIREBASE = 'Firebase',
  CrifQuestionerFlowForKyc = 'CrifQuestionerFlowForKyc',
  KycBusinessPanFlow = 'KycBusinessPanFlow',
  shareMainVan = 'shareMainVan',
  VehicleTrack = 'VehicleTrack',
  userSubUserFlow = 'userSubUserFlow',
  SoundboxSettings = 'SoundboxSettings',
  PgFeesException = 'PgFeesException',
  CAGamification = 'CAGamification',
  PGEnachBlocker = 'PGEnachBlocker',
  VoiceNotificationSettings = 'VoiceNotificationSettings',
  Settlement_Setting = 'Settlement_Setting',
  newKycDocUplodeFlow = 'newKycDocUplodeFlow',
  KnowYourTrader = 'KnowYourTrader',
  Mandi_News = 'Mandi_News',
  My_Branding = 'MyBranding',
  IS_SHOW_PG_CONTRACT_BTN = 'is_show_pg_contract_btn',
  showMainQr = 'showMainQr',
  showCollectionLink = 'showCollectionLink',
  allowNonKycWithdraw = 'allowNonKycWithdraw',
  showInvestNow = 'showInvestNow',
  pgBnplUser = 'Pg_bnpl_user',
  ShowInstaOd = 'Show_Insta_OD',
  PartiesTierBadge = 'PartiesTierBadge',
  NFCCommerce = 'NFC_Commerce',
  NCINVESTMENTS = 'showInvestNow',
  AccountStatement = 'account_statement',
  OneCard = 'one_card',
  My_QR_hp = 'My_QR_hp',
  trade_connect_hp = 'trade_connect_hp',
  ninja_account_hp = 'ninja_account_hp',
  view_contracts = 'view_contracts',
  view_ninja_one = 'view_ninja_one',
  view_ninja_paysure = 'view_ninja_paysure',
  NinjaWifi = 'ninja-wifi',
  HidePayCollect = 'Show_Pay_Collect',
  Newalerts = 'Newalerts',
  MN_HomePage_Experiment = 'MN_HomePage_Experiment',
  Check_Credit_Score = 'check_credit_score',
  Homepage_Commerce_Banner = 'Homepage_Commerce_Banner',
  Device_Block = 'Device_Block',
  retailer_seller_delinquency = 'retailer_seller_delinquency',
  New_home_page = 'New_home_page',
  NTCLoadMatirxLazily = 'NTCLoadMatirxLazily',
  trader_skip_disbursal_flow = 'trader_skip_disbursal_flow',
  sms_home_page = 'sms_home_page',
  Retailer_Super_Seller = 'Retailer_Super_Seller',
  Retailer_Seller_Led_Retention = 'Retailer_Seller_Led_Retention',
  ATB_icon_reseller = 'ATB_icon_reseller',
  QR_universe = 'QR_Universe',
  QR_active_users = 'QRActiveUsers',
}

export enum SplitIoTreatmentStatus {
  ON = 'on',
  OFF = 'off',
  CONTROL = 'control',
}
