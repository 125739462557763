import { triggerEvent as pushEventsToDataLake } from '@utils/events';
import { STORAGE_KEYS } from '@utils/keys';
import { getStorageItem, setStorageItem } from '@utils/storage';
import { EventsData } from 'constants/types';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TraderEventType } from '../utils/eventsConstant';

const useEvents = () => {
  const triggerEvent = useCallback(
    async (
      traderEventType: keyof typeof TraderEventType,
      customEventData?: EventsData
    ) => {
      const session_id = getStorageItem(STORAGE_KEYS.SESSION_ID);
      if (
        !session_id &&
        customEventData?.screenDetails &&
        !customEventData?.screenDetails?.sessionId
      ) {
        const uuid = uuidv4();
        setStorageItem(STORAGE_KEYS.SESSION_ID, uuid);
        customEventData.screenDetails.sessionId = uuid;
      }
      // getNativeUtils()?.recordCleverTapEvent?.(
      //   traderEventType,
      //   customEventData
      // );
      // registerCustomEvent(traderEventType, customEventData);
      pushEventsToDataLake(traderEventType, customEventData);
    },
    []
  );

  return { triggerEvent };
};

export default useEvents;
