import React from 'react';

export const UpArrowIcon = () => {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.364 10.7072C14.1764 10.8947 13.9221 11 13.657 11C13.3918 11 13.1375 10.8947 12.95 10.7072L7.99995 5.75721L3.04995 10.7072C2.86135 10.8894 2.60875 10.9902 2.34655 10.9879C2.08435 10.9856 1.83354 10.8804 1.64813 10.695C1.46272 10.5096 1.35756 10.2588 1.35528 9.99661C1.353 9.73442 1.45379 9.48182 1.63595 9.29321L7.29295 3.63621C7.48048 3.44874 7.73479 3.34343 7.99995 3.34343C8.26512 3.34343 8.51942 3.44874 8.70695 3.63621L14.364 9.29321C14.5514 9.48074 14.6567 9.73505 14.6567 10.0002C14.6567 10.2654 14.5514 10.5197 14.364 10.7072Z"
        fill="#F89A21"
      />
    </svg>
  );
};
