import LocalizedStrings, { LocalizedStringsMethods } from 'react-localization';
import en from '../translations/en.json';
import hi from '../translations/hi.json';
import kn from '../translations/ka.json';
import te from '../translations/tl.json';
import ta from '../translations/tm.json';

export type Strings = typeof en;

export type IStrings = LocalizedStringsMethods & Strings;

export const strings: IStrings = new LocalizedStrings({
  en,
  hi,
  kn,
  te,
  ta,
});
