import { getNativeUtils, redirectToPage } from 'utils/nativeUtils';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useMemo, useState } from 'react';
import { SplitIo } from 'utils/splitIo.enum';
import kmt from 'assets/dashIcons/knowmytrader.svg';
import branding from 'assets/dashIcons/brand.svg';
import track from 'assets/dashIcons/track2.svg';
import instaOdIcon from 'assets/instaod-icon.svg';
import sipIcon from 'assets/dashIcons/sip.svg';
import wifiIcon from 'assets/dashIcons/wifi.svg';
import DashboardIconList from './DashboardIconList';
import useStorage from 'hook/useStorage';
import { STORAGE_KEYS, StorageKeys } from 'utils/keys';
import { useGlobalContext } from 'contexts';
import { DashboardIcon } from 'types/DashboardTypes';
import { useRouter } from 'next/router';
import { updatePaymentConfigforAndroidVersion } from '@utils/commonUtils';
import {
  AppName,
  APP_NAME,
  MANDI_NEWS_APP_VERSION,
  NINJA_WIFI_ENABLE_FACILITY,
  UserTypeID,
} from 'constants/common';
import { getAuthData, getWin, setStorageItem } from '@utils/storage';
import { TraderEventType } from '@utils/eventsConstant';
import useEvents from 'hook/useEvents';
import { getStorageItem } from '@utils/storage';
import pa from 'assets/dashIcons/payment_assurance.png';
import business from 'assets/dashIcons/Business.png';
import ninjaAccount from 'assets/dashIcons/ninjaAccount.svg';
import ViewCon from 'assets/dashIcons/History.png';
import { ROUTE_NAMES } from '@utils/routes';
import { IContractsSDKData } from 'pages/contracts';
import { strings } from 'constants/translations';
import NinjaAccountIcon from 'assets/dashboardIcons/NinjaAccount.svg';
import ContractsIcon from 'assets/dashboardIcons/Contract.svg';
import PaymentAssIcon from 'assets/dashboardIcons/PaymentAssurance.svg';
import TrackIcon from 'assets/dashboardIcons/Track.svg';
import WifiIcon from 'assets/dashboardIcons/Wifi.svg';
import { DownArrowIcon } from 'assets/dashboardIcons/downArrowIcon';
import DashboardIconListNew from '@components/molecules/DashboardNewIcons/iconComponent';
import InvestIcon from 'assets/dashboardIcons/investmentIcon.svg';
import { UpArrowIcon } from 'assets/dashboardIcons/upArrowIcon';
import InstaOD from 'assets/dashboardIcons/InstaOD.svg';
import myQR from 'assets/icons/myQR.svg';
import Atb from 'assets/dashboardIcons/AtbOtherOfferings.svg';
import QrOtherOfferings from 'assets/dashboardIcons/qr_other_offerings.svg';
import { isEmpty } from 'lodash-es';
import { useKycSummary } from 'queries/ekyc/useKycSummary';
import useHomepageExperiment from 'hook/useHomepageExperiment';

type Props = {
  isStaffView: boolean;
  providedId: number | undefined;
  isNew?: boolean;
};

export const NFCCommercePageId = 'nfc_commerce';

export const OtherOfferingsIcons = {
  NFCCommerce: 'Trade Connect',
  ninjaAccount: 'Ninja Account',
  ViewContracts: 'View Contracts',
  ExploreBusiness: 'Explore Businesses',
  PaymentAssurance: 'Payment Assurance',
  Track: 'Track',
  KnowMyTrade: 'Know my trader',
  WhatsAppPosts: 'WhatsApp Posts',
};

const ITEM_LIST = (isNew: boolean): DashboardIcon[] => {
  return [
    {
      menuName: OtherOfferingsIcons.ninjaAccount,
      name: strings.Ninja_Account,
      icon: isNew ? NinjaAccountIcon : ninjaAccount,
      id: 1,
      restricted: true,
      key: SplitIo.ninja_account_hp,
      elementId: 'ninja_account',
    },
    {
      menuName: OtherOfferingsIcons.ExploreBusiness,
      name: strings.exploreBusiness,
      icon: business,
      id: 2,
      url: 'commerce/',
      key: SplitIo.ExploreBusiness,
      restricted: true,
      elementId: 'explore_businesses',
    },
    {
      menuName: OtherOfferingsIcons.ViewContracts,
      name: strings.View_Contracts,
      icon: isNew ? ContractsIcon : ViewCon,
      id: 3,
      url: 'contracts',
      key: SplitIo.view_contracts,
      restricted: true,
      elementId: 'view_contracts',
    },
    {
      menuName: OtherOfferingsIcons.PaymentAssurance,
      name: strings.Payment_Assurance,
      icon: isNew ? PaymentAssIcon : pa,
      id: 4,
      css: true,
      url: '/payment-guarantee/details',
      staffAccess: false,
      restricted: true,
      key: SplitIo.PaymentAssurance,
      elementId: 'payment_assurance',
    },
    {
      menuName: OtherOfferingsIcons.Track,
      name: strings.track,
      icon: isNew ? TrackIcon : track,
      id: 1,
      url: '',
      staffAccess: true,
      restricted: true,
      key: SplitIo.VehicleTrack,
      elementId: 'track',
    },
    {
      menuName: OtherOfferingsIcons.KnowMyTrade,
      name: OtherOfferingsIcons.KnowMyTrade,
      icon: kmt,
      id: 5,
      css: true,
      url: '',
      staffAccess: false,
      restricted: true,
      key: SplitIo.KnowYourTrader,
      elementId: 'know_my_trader',
    },
    {
      menuName: OtherOfferingsIcons.WhatsAppPosts,
      name: OtherOfferingsIcons.WhatsAppPosts,
      icon: branding,
      id: 6,
      url: '',
      staffAccess: false,
      restricted: true,
      key: SplitIo.My_Branding,
      elementId: 'whatsapp_posts',
    },
    {
      menuName: APP_NAME.INSTA_OD,
      name: strings.InstaOD,
      icon: isNew ? InstaOD : instaOdIcon,
      id: 7,
      url: '/insta-od-about',
      staffAccess: false,
      restricted: true,
      key: SplitIo.ShowInstaOd,
      customClass: 'tw-h-12',
      elementId: 'insta_od',
    },
    {
      menuName: APP_NAME.INVESTMENT,
      name: strings.Smart_Invest,
      icon: isNew ? InvestIcon : sipIcon,
      id: 8,
      url: '',
      staffAccess: false,
      restricted: true,
      key: SplitIo.NCINVESTMENTS,
      elementId: 'smart_invest',
    },
    {
      menuName: APP_NAME.NINJA_WIFI,
      name: strings.Ninja_WiFi,
      icon: isNew ? WifiIcon : wifiIcon,
      css: true,
      id: 9,
      url: '',
      staffAccess: false,
      restricted: true,
      key: SplitIo.NinjaWifi,
      elementId: 'wifi',
    },
    {
      menuName: APP_NAME.MY_QR,
      name: strings.My_QR,
      icon: isNew ? QrOtherOfferings : myQR,
      css: true,
      id: 10,
      url: '',
      staffAccess: false,
      restricted: true,
      key: SplitIo.My_QR_hp,
      elementId: 'my_qr',
    },
    {
      menuName: APP_NAME.AGRI_TRADE_BUREAU,
      name: strings.check_trust_score,
      icon: Atb,
      css: true,
      id: 11,
      url: '',
      staffAccess: false,
      restricted: true,
      key: SplitIo.ATB_icon_reseller,
      elementId: 'atb',
    },
  ];
};

export default function OtherOfferings(props: Props) {
  const { isStaffView, providedId, isNew = false } = props;
  const { triggerEvent } = useEvents();
  const [viewMore, setViewMore] = useState(false);
  const data = getStorageItem(STORAGE_KEYS.DASHBOARD_DATA);
  const { accounts } = data || {};
  const { qrAccount } = accounts || {};
  const androidVersion = +(getStorageItem(STORAGE_KEYS.APP_BUILD_VERSION) || 0);
  const { showMandiNewsAsHomePage } = useHomepageExperiment();
  const treatments = useTreatments([
    SplitIo.ExploreBusiness,
    SplitIo.PaymentAssurance,
    SplitIo.ninja_account_hp,
    SplitIo.view_contracts,
    SplitIo.LoanHpIcon,
    SplitIo.VehicleTrack,
    SplitIo.KnowYourTrader,
    SplitIo.My_Branding,
    SplitIo.PAY_LATER,
    SplitIo.MultiBanking,
    SplitIo.ShowInstaOd,
    SplitIo.NCINVESTMENTS,
    SplitIo.NinjaWifi,
    SplitIo.ATB_icon_reseller,
    SplitIo.My_QR_hp,
    SplitIo.QR_universe,
    SplitIo.QR_active_users,
  ]);

  const router = useRouter();
  const { getItem, setItem } = useStorage();
  const isAndroid = getItem(STORAGE_KEYS.IS_ANDROID);
  const { setShowBalanceClicked, setShowQrModal } = useGlobalContext();
  const authData = getAuthData();
  const { refetch: fetchKycLiteSummary } = useKycSummary(
    authData.realmIdentifier,
    authData.userId,
    UserTypeID.KYC_LITE
  );

  const openApps = async (url: string, appName: string) => {
    setShowBalanceClicked(false);
    triggerEvent(TraderEventType.Generic_event, { eventName: appName });
    const redirectionUrl = `${process.env.NEXT_PUBLIC_WEBVIEW_URL}${url}`;

    if (appName === OtherOfferingsIcons.ninjaAccount) {
      triggerEvent(TraderEventType.Generic_event, {
        eventName: TraderEventType.Ninja_account_initiate,
      });
      router.push(ROUTE_NAMES.NINJA_ACCOUNT);
      return;
    }

    if (appName === OtherOfferingsIcons.ExploreBusiness) {
      await triggerEvent(TraderEventType.Generic_event, {
        eventName: TraderEventType.Exp_Bus_Initiate,
      });
      redirectToPage(redirectionUrl, 'Commerce-tabs/discover');
      return;
    }

    if (appName === OtherOfferingsIcons.PaymentAssurance) {
      triggerEvent(TraderEventType.Generic_event, {
        eventName: TraderEventType.Payment_Assurance_Initiate,
      });
      const additionalDetails = [
        { refType: 'ProductName', refValue: appName },
        { refType: 'CTAPlacement', refValue: 'TraderHomepage_Offerings' },
      ];
      triggerEvent(TraderEventType.Generic_event, {
        eventName: TraderEventType.Visit_Assurance_Home_Initiate,
        eventType: 'UserAction',
        additionalDetails,
      });
      router.push(url);
      return;
    }

    if (appName == OtherOfferingsIcons.ViewContracts) {
      triggerEvent(TraderEventType.Generic_event, {
        eventName: TraderEventType.View_Contract_Initiate,
      });
      const contractsSdkData: IContractsSDKData = {
        toPage: 'CONTRACT_LISTING',
        fromPage: 'DASHBOARD',
      };
      router.push({
        pathname: ROUTE_NAMES.CONTRACTS,
        query: {
          contractsSdkData: JSON.stringify(contractsSdkData),
        },
      });
      return;
    }

    if (appName === APP_NAME.INSTA_OD) {
      triggerEvent(TraderEventType.Generic_event, {
        eventName: TraderEventType.Instaod_inititate,
      });
    }

    if (appName == OtherOfferingsIcons.Track) {
      triggerEvent(TraderEventType.Generic_event, {
        eventName: TraderEventType.Track_HP_Initiate,
      });
    }
    if (appName === 'My QR') {
      triggerEvent(TraderEventType.Generic_event, {
        eventName: 'QR_hp_icon_initiate',
      });
      if (isEmpty(qrAccount)) {
        setShowQrModal(true);
        return;
      }
      await fetchKycLiteSummary();

      if (getNativeUtils() && androidVersion < 149) {
        redirectToPage('/qr-segment');
      } else {
        updatePaymentConfigforAndroidVersion();
        getNativeUtils()?.openWebview(
          `${process.env.NEXT_PUBLIC_QR_URL}`,
          'QRSDK',
          getWin().localStorage
        );
      }
      return;
    }

    // inside condition is same code as used for mandi news mfe
    if (appName === APP_NAME.AGRI_TRADE_BUREAU) {
      try {
        await triggerEvent(TraderEventType.Generic_event, {
          eventName: TraderEventType.Atb_Icon_Click,
        });
      } catch (error) {
        console.error(error);
      }
      if (androidVersion > MANDI_NEWS_APP_VERSION && isAndroid) {
        if (!showMandiNewsAsHomePage) {
          setStorageItem(
            STORAGE_KEYS.MANDI_NEWS_TIMESTAMP as StorageKeys,
            Date.now()
          );
        }
        triggerEvent(TraderEventType.Generic_event, {
          eventName: 'Mandi News',
        });
      }
      const url = process.env.NEXT_PUBLIC_MANDI_NEWS_ATB;
      redirectToPage(url as string, AppName.MandiNews, '');
      return;
    }

    // NOTE: Enable this for migrated PG
    if (appName == APP_NAME.PAYMENT_ASSURANCE || appName == APP_NAME.INSTA_OD) {
      router.push(url);
      return;
    }

    if (appName === APP_NAME.INVESTMENT) {
      await triggerEvent(TraderEventType.Generic_event, {
        eventName: TraderEventType.Investment_icon_initiate,
      });
      const { PAYMENTS_CONFIG = '{}', realmOwnerData = '{}' } =
        getWin().localStorage;
      const updatedPaymentsConfig = { ...JSON.parse(PAYMENTS_CONFIG) };
      const parsedRealmOwnerData = JSON.parse(realmOwnerData);
      if (parsedRealmOwnerData?.systemUserId) {
        updatedPaymentsConfig['systemUserId'] =
          parsedRealmOwnerData.systemUserId;
      }
      redirectToPage(`${process.env.NEXT_PUBLIC_SIP_URL}`, 'InvestmentMfe', {
        ...getWin().localStorage,
        PAYMENTS_CONFIG: JSON.stringify(updatedPaymentsConfig),
        tool_id: process.env.NEXT_PUBLIC_TOOL_ID,
      });
      return;
    }

    if (appName === APP_NAME.NINJA_WIFI) {
      await triggerEvent(TraderEventType.Generic_event, {
        eventName: TraderEventType.WIFI_ICON_CLICK,
      });
      const androidVersion = +(
        getStorageItem(STORAGE_KEYS.APP_BUILD_VERSION) || 0
      );
      if (
        process.env.NEXT_PUBLIC_NINJA_WIFI_URL &&
        getNativeUtils() &&
        androidVersion >= 165
      ) {
        redirectToPage(
          `${process.env.NEXT_PUBLIC_NINJA_WIFI_URL}`,
          'NINJA_WIFI',
          getWin().localStorage
        );
      } else {
        console.warn(
          'NINJA_WIFI_URL not available',
          process.env.NEXT_PUBLIC_NINJA_WIFI_URL
        );
      }
      return;
    }

    if (url === '') {
      setItem(appName as StorageKeys, true);
    }

    redirectToPage(url, appName);
  };

  const offeringsList: DashboardIcon[] = useMemo(() => {
    const userRealmData = getStorageItem(STORAGE_KEYS.USER_REALM_DATA);
    const facilityId = userRealmData?.organizationFacilityId as string;
    setItem('multiBankingEnabled', treatments.MultiBanking?.treatment === 'on');
    return ITEM_LIST(isNew).filter((item) => {
      const { key, restricted, staffAccess, id, menuName } = item;
      let hasAccess = true;
      let hasStaffAccess = true;
      if (key && restricted) {
        hasAccess = treatments[key].treatment === 'on';
      }

      if (isStaffView) {
        hasStaffAccess = Boolean(staffAccess);
      }

      if (restricted && id == 12 && key) {
        hasAccess = treatments[key].treatment === 'on';
      }
      if (menuName === APP_NAME.NINJA_WIFI) {
        const isEnabled =
          NINJA_WIFI_ENABLE_FACILITY.includes(facilityId) ||
          !facilityId?.trim();
        hasAccess = isEnabled;
      }
      if (
        item.menuName === OtherOfferingsIcons.ninjaAccount &&
        providedId !== 1
      ) {
        hasAccess = false;
      }
      if (
        item?.elementId === 'my_qr' &&
        treatments[SplitIo.ATB_icon_reseller]?.treatment === 'on' &&
        treatments[SplitIo.QR_universe]?.treatment === 'on' &&
        treatments[SplitIo.QR_active_users]?.treatment === 'on'
      ) {
        hasAccess = false;
      }
      if (
        item?.elementId === 'my_qr' &&
        !(treatments[SplitIo.ATB_icon_reseller]?.treatment === 'on')
      ) {
        hasAccess = false;
      }
      if (
        item?.elementId === 'my_qr' &&
        (!(treatments[SplitIo.QR_universe]?.treatment === 'on') ||
          !(treatments[SplitIo.QR_active_users]?.treatment === 'on')) &&
        treatments[SplitIo.My_QR_hp]?.treatment === 'on' &&
        treatments[SplitIo.ATB_icon_reseller]?.treatment === 'on'
      ) {
        hasAccess = true;
      }
      if (
        item?.elementId === 'atb' &&
        (!(treatments[SplitIo.QR_universe]?.treatment === 'on') ||
          !(treatments[SplitIo.QR_active_users]?.treatment === 'on') ||
          !(treatments[SplitIo.My_QR_hp]?.treatment === 'on'))
      ) {
        hasAccess = false;
      }

      return hasAccess && hasStaffAccess;
    });
  }, [treatments, setItem, isStaffView]);

  return (
    <div>
      <div
        className={`tw-mb-[16px] tw-bg-white ${
          isNew ? '' : 'tw-rounded-lg tw-border tw-border-gray-300 tw-pb-[10px]'
        }`}
      >
        <div
          className={`tw-pb-[5px] ${
            isNew
              ? 'bodyHeading tw-pl-4 tw-font-semibold tw-text-blackText'
              : 'tw-pl-4 tw-pt-[10px] tw-text-[14px] tw-font-[700]'
          }`}
        >
          {strings.Other_Offerings}
        </div>
        {isNew ? (
          <>
            <DashboardIconListNew
              list={offeringsList?.slice(0, 6)}
              onClick={openApps}
            />
            {viewMore && (
              <DashboardIconListNew
                list={offeringsList?.slice(6)}
                onClick={openApps}
              />
            )}
            {offeringsList.length > 6 && (
              <div
                className="tw-border-ninja-gray-border flex items-center justify-center tw-mx-4 tw-mt-2.5 tw-h-[45px] tw-rounded-lg tw-border-[0.5px] tw-bg-grayColor"
                onClick={() => {
                  setViewMore(!viewMore);
                }}
              >
                <p className="bodyHeading tw-mr-1 tw-font-medium tw-text-ninja-primary-600">
                  {viewMore ? 'View Less' : 'View More'}
                </p>
                {viewMore ? <UpArrowIcon /> : <DownArrowIcon />}
              </div>
            )}
          </>
        ) : (
          <DashboardIconList list={offeringsList} onClick={openApps} />
        )}
      </div>
    </div>
  );
}
