import { useMemo } from 'react';
import { getStorageItem } from '@utils/storage';
import { STORAGE_KEYS } from '@utils/keys';
import { mandiNewsExperimentVersion } from 'constants/common';
import { useTreatments } from '@splitsoftware/splitio-react';
import { SplitIo } from '@utils/splitIo.enum';

const useHomepageExperiment = () => {
  const appVersion = getStorageItem(STORAGE_KEYS.APP_BUILD_VERSION);
  const isAndroid = getStorageItem(STORAGE_KEYS.IS_ANDROID);

  const treatments = useTreatments([SplitIo.MN_HomePage_Experiment]);

  const showMandiNewsAsHomePage = useMemo(
    () =>
      appVersion &&
      +appVersion > mandiNewsExperimentVersion &&
      isAndroid &&
      treatments[SplitIo.MN_HomePage_Experiment].treatment === 'on',
    [appVersion, mandiNewsExperimentVersion, isAndroid, treatments]
  );

  return {
    showMandiNewsAsHomePage,
  };
};

export default useHomepageExperiment;
