import {
  IProfileParams,
  publishAllEvents,
  setEventUserProfile,
} from '@ninjacart/nc-events-sdk';
import { EventsData } from 'constants/types';
import { sanitizePhoneNumber } from './Utils';
import { TraderEventType } from './eventsConstant';
import { STORAGE_KEYS } from './keys';
import { getNativeUtils } from './nativeUtils';
import { getAuthData, getStorageItem } from './storage';

export function getEventsConfig() {
  const appVersion = getStorageItem(STORAGE_KEYS.APP_BUILD_VERSION);
  const appVersionName = getStorageItem(STORAGE_KEYS.APP_BUILD_VERSION_NAME);
  const deviceDetails = getStorageItem(STORAGE_KEYS.DEVICE_DETAILS);
  const { realmIdentifier, userId, systemUserId, realmName } = getAuthData();

  const config = {
    appDetails: {
      appId: process.env.NEXT_PUBLIC_APP_ID,
      appVersion: appVersion?.toString() || 'web',
      appVersionName: appVersionName?.toString() || 'web',
      appType: getNativeUtils() ? 'Mobile' : 'Web',
      deviceId: deviceDetails?.deviceId?.toString() || 'web',
      url: window.location.origin || '',
      browser: 'chrome',
    },
    sourceDetails: {
      sourceType: 'TRADER_APP',
    },
    userDetails: {
      userId: `${userId || 1}`,
      realmId: realmIdentifier || '1',
      systemUserId: `${systemUserId || 1}`,
      userName: realmName || 'Not Available',
    },
    env: process.env.NEXT_PUBLIC_WORKFLOW_ENV || 'dev',
    cleverTapKey: process.env.NEXT_PUBLIC_CLEVERTAP_KEY,
    // hanselAccountID: process.env.NEXT_PUBLIC_HANSEL_ACCOUNT_ID,
    // hanselKey: process.env.NEXT_PUBLIC_HANSEL_KEY,
  };

  return config;
}

export function initializeEventsUserProfile() {
  const realmOwnerData = getStorageItem(STORAGE_KEYS.REALM_OWNER_DATA);
  const authData = getAuthData();
  const {
    realmIdentifier,
    systemUserId = 0,
    userId = 0,
    realmName = '',
    contactNumber,
    token_type,
    token,
  } = authData;

  if (authData) {
    const cleverTapIdentityData: IProfileParams = {
      userName: realmName,
      systemUserId: systemUserId?.toString(),
      userId: userId?.toString(),
      realmId: realmIdentifier,
      phone: sanitizePhoneNumber(contactNumber),
      ninjaUserId: realmOwnerData?.asgardUserId,
      accessToken: `${token_type} ${token}`,
      msgEmail: false,
      msgPush: true,
      msgSms: true,

      msgWhatsapp: true,
    };

    setEventUserProfile(cleverTapIdentityData);
  }
}

export function triggerEvent(
  traderEventType: keyof typeof TraderEventType,
  customEventDetails?: EventsData
) {
  const additionalDetails = customEventDetails?.additionalDetails || [];
  const geolocation = getStorageItem(STORAGE_KEYS.GEO_LOCATION);
  const originalDevice = getStorageItem(STORAGE_KEYS.ORIGINAL_DEVICE);
  const deviceDetails = getStorageItem(STORAGE_KEYS.DEVICE_DETAILS);
  additionalDetails.push(
    {
      refType: 'Latitude-Longitude',
      refValue: geolocation ? geolocation : `{ '-1,-1'}`,
    },
    {
      refType: 'deviceId',
      refValue: deviceDetails?.deviceId?.toString(),
    },
    {
      refType: 'originalDevice',
      refValue: originalDevice ? originalDevice : false,
    }
  );

  publishAllEvents({
    eventName: customEventDetails?.eventName || '',
    eventData: {
      name: customEventDetails?.eventName || '',
      type:
        customEventDetails?.eventType || customEventDetails?.eventName || '',
      subType:
        customEventDetails?.subType || customEventDetails?.eventName || '',
      description:
        customEventDetails?.description || customEventDetails?.eventName,
      additionalDetails,
      screenDetails: customEventDetails?.screenDetails || {},
    },
  });
}
