import { AuthData, StorageData } from 'types/Storage';
import { StorageKeys, STORAGE_KEYS } from './keys';

type NCWindow = Window &
  typeof globalThis & {
    smartech?: any;
    Hansel?: any;
  };

export function getWin(): NCWindow {
  return window;
}

export const getStorageItem = <T extends StorageKeys>(
  key: T,
  window?: NCWindow
): StorageData[T] | undefined => {
  try {
    window = window || getWin();
    const result = window?.localStorage?.getItem(key);

    if (result) {
      try {
        return JSON.parse(result) as StorageData[T];
      } catch (e) {
        return result as StorageData[T];
      }
    }
    return undefined;
  } catch (err) {
    return undefined;
  }
};

export const setStorageItem = <T extends StorageKeys>(
  key: T,
  value: StorageData[T],
  window?: NCWindow
) => {
  try {
    window = window || getWin();
    return window?.localStorage?.setItem(key, JSON.stringify(value));
  } catch (err) {
    return undefined;
  }
};

export const clearStorageItem = <T extends StorageKeys>(
  key: T,
  window?: NCWindow
) => {
  try {
    window = window || getWin();
    return window?.localStorage?.removeItem(key);
  } catch (err) {
    return undefined;
  }
};

export const getAuthData = () =>
  getStorageItem(STORAGE_KEYS.AUTH_DATA) || ({} as AuthData);
