import { STORAGE_KEYS } from './keys';
import { getStorageItem, setStorageItem } from './storage';
import { NFCCommercePageId } from '@components/molecules/DashboardIcons/OtherOfferings';
import { NFC_COMMERCE_DATA } from 'constants/common';

export function getNativeUtils() {
  return window?.Native;
}

export function redirectToPage(url: string, title?: string, data?: any) {
  const base = process.env.NEXT_PUBLIC_WEBVIEW_URL;
  const redirectionUrl = `${base}${url}`.replace(/([^:]\/)\/+/g, '$1');

  const result = url.startsWith('http') ? url : redirectionUrl;

  if (getNativeUtils()) {
    getNativeUtils().openWebview(result, title || url, data);
  } else {
    window.location.replace(result);
  }
}

export function copyToClipboard(text: string) {
  if (getNativeUtils()) {
    getNativeUtils().copiedTextFromWeb(text);
  } else {
    navigator.clipboard.writeText(text);
  }
}

export const setNativeSessionData = (marketName?: string) => {
  const realmOwnerData = getStorageItem(STORAGE_KEYS.REALM_OWNER_DATA);
  const authData = getStorageItem(STORAGE_KEYS.AUTH_DATA);
  if (authData && typeof authData === 'object') {
    const cleverTapIdentityData = {
      Name: realmOwnerData?.realmName,
      Identity: realmOwnerData?.asgardUserId,
      Phone: realmOwnerData?.contactNumber,
      'MSG-email': false,
      'MSG-push': true,
      'MSG-sms': true,
      'MSG-whatsapp': true,
      NinjaUserId: realmOwnerData?.asgardUserId,
      MarketName: marketName ? marketName : null,
    };

    authData[NFCCommercePageId] = NFC_COMMERCE_DATA;
    setStorageItem(STORAGE_KEYS.AUTH_DATA, authData);
    getNativeUtils?.()?.onLoginCallback?.(cleverTapIdentityData, authData);
  }
};
