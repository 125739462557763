import { StorageKeys } from '@utils/keys';
import {
  clearStorageItem,
  getStorageItem,
  setStorageItem,
} from '@utils/storage';
import isEmpty from 'lodash-es/isEmpty';
import { useCallback, useEffect, useState } from 'react';
import { StorageData } from 'types/Storage';

type SetItemParams = {
  [key in StorageKeys]: any;
};

export default function useStorage() {
  const [windowObj, setWindowObj] = useState<Window & typeof globalThis>();

  useEffect(() => {
    if (isEmpty(windowObj)) {
      setWindowObj(window);
    }
  }, [windowObj]);

  const getWindow = useCallback(
    () => (typeof window !== 'undefined' ? window : windowObj),
    [windowObj]
  );

  const getItem = useCallback(
    <T extends StorageKeys>(key: T): StorageData[T] | undefined =>
      getStorageItem(key, getWindow()),
    [getWindow]
  );

  const setItem = useCallback(
    <T extends StorageKeys>(key: T, value: StorageData[T]) =>
      setStorageItem(key, value, getWindow()),
    [getWindow]
  );

  const setItems = (items?: SetItemParams) => {
    if (!isEmpty(items)) {
      Object.entries(items)?.map(([key, value]) =>
        setStorageItem(key as StorageKeys, value, getWindow())
      );
    }
  };

  const getItems = useCallback(
    <T extends StorageKeys[]>(keys: T) =>
      keys?.map((key) => getStorageItem(key, getWindow())),
    [getWindow]
  );

  const clearItem = useCallback(
    <T extends StorageKeys>(key: T) => clearStorageItem(key, getWindow()),
    [getWindow]
  );

  return { getItem, setItem, setItems, getItems, clearItem, windowObj };
}
