import { StorageData } from 'types/Storage';

export enum STORAGE_KEYS {
  CUSTOM_DOMAIN = 'customDomain',
  AUTH_DATA = 'authData',
  PAYMENT_CONFIG = 'PAYMENTS_CONFIG',
  REALM_OWNER_DATA = 'realmOwnerData',
  USER_REALM_DATA = 'userRealmData',
  FIREBASE_TOKEN = 'firebaseToken',
  DASHBOARD_DATA = 'dashboardData',
  USER_PHONE = 'contact',
  CONNECTED_ACCOUNTS = 'connectedAccounts',
  ACCOUNT = 'accounts',
  IS_STAFF_VIEW = 'isStaffView',
  KYC_STATUS = 'kycStatus',
  KYC_LITE_STATUS = 'kycLiteStatus',
  KYC_REUPLOAD = 'kycReupload',
  APP_BUILD_VERSION = 'app-build-version',
  GEOLOCATION = 'geo-location',
  APP_BUILD_VERSION_NAME = 'app-build-version-name',
  SELECTED_CONTACT = 'SELECTED_CONTACT',
  PARTIES_CONFIG = 'parties-config',
  IS_ADD_TEAM_TOGGLE = 'isAddTeamToggle',
  LOCATION_PERMISSION = 'location-permission-granted',
  CAMERA_PERMISSION = 'camera-permission-granted',
  STORAGE_PERMISSION = 'storage-permission-granted',
  CONSENT = 'consent',
  DIGIO_REQUEST_ID = 'digioRequestId',
  FEATURE_CALCULATION_COMPLETED = 'isFeatureCalculationCompleted',
  E_SIGN_DIGIO_DOC_INFO = 'eSignDigioDocInfo',
  E_NACH_DIGIO_DOC_INFO = 'eNachDigioDocInfo',
  LANGUAGE_CODE = 'languageCode',
  NUDGE_COMPLETE = 'nudgeComplete',
  LOCATION_PERMISSION_ASK_COUNT_PAGE = 'locationPermissionAskCountHomePage',
  LAST_TIME_LOCATION_REQUESTED = 'lastTimeLocationRequested',
  DEVICE_DETAILS = 'device_details',
  NSPL_CONSENT_GIVEN = 'nspl_consent_given',
  MULTI_BANKING_ENABLED = 'multiBankingEnabled',
  IS_ANDROID = 'isAndroid',
  SELECTED_FOOTER = 'selectedFooter',
  BUSINESS_PROFILE_FIRST_SCREEN = 'firstScreen',
  BUSINESS_PROFILE_SECOND_SCREEN = 'secondScreen',
  BUSINESS_PROFILE_SUBCATEGORY_SCREEN = 'subCategoryScreen',
  PERMISSION = 'permission',
  ROLES = 'roles',
  SN_ORG_PROFILE = 'sn-org-profile',
  SIGN_UP = 'sign_up',
  ORGANIZATION = 'organization',
  MANDI_NEWS_TIMESTAMP = 'mandiNewsTimestamp',
  APP_ID = 'appId',
  GEO_LOCATION = 'geolocation',
  ORIGINAL_DEVICE = 'originalDevice',
  BACKEND_ORIGINAL_DEVICE_UPDATED = 'backendOriginalDeviceUpdated',
  ENABLE_WHATSAPP_COMMUNICATION = 'enableWhatsAppCommunication',
  TOOL_ID = 'tool_id',
  IS_ONBOARDING = 'is_onboarding',
  NTC_MATRIX_LAZYLOAD = 'isNtcMatrixLazyLoad',
  TRANSACTION_DETAILS = 'transactionDetails',
  SMS_PERMISSION = 'sms-permission-granted',
  SMS_CONSENT_COUNTER = 'smsConsentCounter',
  LIMIT_SET_FLAG = 'limitSetFlag',
  NEW_DASHBOARD = 'NewDashboard',
  STORIES_VIEWED = 'storiesViewed',
  SHOW_KIRANA_SELLER_BS = 'showKiranaSellerBs',
  firebaseInstanceId = 'firebaseInstanceId',
  NTC_ENABLED = 'ntc_enabled',
  ADVERTISING_ID_PRESENT = 'advertisingIdPresent',
  FTB_BUYERS_DETAILS = 'ftbDetails',
  SELLER_IS_SUPER_SELLER = 'sellerIsSuperSeller',
  SESSION_ID = 'SESSION_ID',
  NEXT_SMS_PULL = 'nextSMSPullDate',
  CONTACT_PERMISSION = 'contact-permission-granted',
  CONTACT_TAKEN = 'retailer-contact-taken',
}

export const KEYS_TO_STORE = [
  'isAndroid',
  'app-build-version-name',
  'app-build-version',
  'ninja-trader-fcm-token',
  'isLoansNotIframe',
  'isWebView',
  'ninja-trader-contact-list',
  'camera-permission-granted',
  'contact-permission-granted',
  'storage-permission-granted',
  'location-permission-granted',
  'locationPermissionAskCountHomePage',
  'lastTimeLocationRequested',
  'sms-permission-granted',
  'firebaseInstanceId',
  STORAGE_KEYS.APP_ID,
];

export type StorageKeys = keyof StorageData;
