import Image from 'next/image';
import React from 'react';
import isEmpty from 'lodash-es/isEmpty';
import { DashboardIcon } from 'types/DashboardTypes';
import useEvents from 'hook/useEvents';
import { TraderEventType } from '@utils/eventsConstant';
import { onVisible } from '@utils/Utils';
import useStorage from 'hook/useStorage';
import { STORAGE_KEYS } from '@utils/keys';

// TODO: Verify Money transfer links
// TODO: Combine all onClicks

interface DashboardIconProps extends DashboardIcon {
  onClick: (url: string, menuName: string) => void;
}

function DashboardIcon(props: DashboardIconProps) {
  const {
    newFeature,
    menuName,
    url,
    icon,
    id,
    elementId,
    css,
    customClass,
    onClick,
    name,
  } = props;

  const { getItem } = useStorage();
  const { triggerEvent } = useEvents();

  const checkMenuLoaded = () => {
    if (menuName === 'Mandi News') {
      onVisible(document.querySelector(`#${elementId}`), () => {
        const additionalDetails = [
          {
            refType: 'trader_name',
            refValue: getItem(STORAGE_KEYS.REALM_OWNER_DATA)?.realmName,
          },
          {
            refType: 'AsgardId',
            refValue: JSON.stringify(
              getItem(STORAGE_KEYS.REALM_OWNER_DATA)?.asgardUserId
            ),
          },
          {
            refType: 'app_version',
            refValue: getItem(STORAGE_KEYS.APP_BUILD_VERSION) || 'Browser',
          },
          {
            refType: 'visit_date',
            refValue: JSON.stringify(new Date().getTime()),
          },
        ];
        triggerEvent(TraderEventType.Generic_event, {
          eventName: TraderEventType.MandiNews_Icon_Viewed,
          additionalDetails,
        });
      });
    }
  };

  return (
    <div
      key={id}
      className="flex-col items-center justify-start relative tw-mb-[10px] tw-flex tw-pl-[10px] tw-pr-[10px]" //self-center
      style={{
        flex: '0 0 25%',
        maxWidth: '25%',
      }}
      onClick={() => onClick(url || '', menuName)}
    >
      {newFeature ? (
        <div className="tw-absolute tw-top-[-7px] tw-right-[20px] tw-rounded-xl tw-bg-[#EFB546] tw-px-1 tw-text-[9px]">
          New!
        </div>
      ) : null}
      <Image
        alt=""
        className={customClass}
        height={css ? 36 : 50}
        id={elementId}
        src={icon}
        style={{
          ...(css
            ? {
                margin: '0 auto',
                marginTop: '8px',
                marginBottom: '6px',
              }
            : { margin: '0 auto' }),
        }}
        width={css ? 36 : 50}
        onLoad={() => (checkMenuLoaded ? checkMenuLoaded() : {})}
      />
      <div
        className={'tw-text-[10px]'}
        style={{
          width: '90%',
          textAlign: 'center',
          overflowWrap: 'break-word',
          lineHeight: '1.2',
        }}
      >
        {name}{' '}
      </div>
    </div>
  );
}

interface IProps {
  list: DashboardIcon[];
  onClick: (url: string, menuName: string) => {};
}

// TODO: Make DashboardIcon as renderProps
function DashboardIconList({ list, onClick }: IProps) {
  return (
    <div className="tw-flex tw-flex-wrap">
      {!isEmpty(list) &&
        list.map((item) => (
          <DashboardIcon {...item} key={item.menuName} onClick={onClick} />
        ))}
    </div>
  );
}

export default DashboardIconList;
