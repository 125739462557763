import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { ROUTE_NAMES } from 'utils/routes';
import { getStorageItem } from '@utils/storage';
import { STORAGE_KEYS } from '@utils/keys';

const OPEN_ROUTES = [
  ROUTE_NAMES.LANGUAGE,
  ROUTE_NAMES.PRIVACY_POLICY,
  ROUTE_NAMES.TERMSCONDITION,
];

const AUTH_ROUTES = [
  ROUTE_NAMES.AGREEMENT,
  ROUTE_NAMES.LOGIN,
  ROUTE_NAMES.OTP,
  '/intro-one',
  '/intro-two',
  '/intro-three',
  '/intro-four',
];

const PUBLIC_ROUTES = [...OPEN_ROUTES, ...AUTH_ROUTES];

function checkRoute(pathname: string, routes: string[]) {
  return routes.some((route) => pathname.startsWith(route));
}

function RouteGuard({ children }) {
  const router = useRouter();
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    // on initial load - run auth check
    authCheck(router.asPath);

    // on route change start - hide page content by setting authorized to false
    const hideContent = () => setAuthorized(false);
    router.events.on('routeChangeStart', hideContent);

    // on route change complete - run auth check
    router.events.on('routeChangeComplete', authCheck);

    // unsubscribe from events in useEffect return function
    return () => {
      router.events.off('routeChangeStart', hideContent);
      router.events.off('routeChangeComplete', authCheck);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function authCheck(url: string) {
    const [path, search = ''] = url.split('?');
    const pathname = path.replace('/trader-new', '');

    const isToken = !!getStorageItem(STORAGE_KEYS.AUTH_DATA)?.token;

    const language = '/language';
    const dashboard = '/dashboard';

    const isAuthRoute = checkRoute(pathname, AUTH_ROUTES);
    if (pathname === '/') {
      const initial = isToken ? dashboard : language;
      router.push({
        pathname: initial,
        query: window.location.search.replace('?', ''),
      });
      return;
    }

    const isPublicRoute = checkRoute(pathname, PUBLIC_ROUTES);

    if (!isToken && !isPublicRoute) {
      // Deep linking for urls
      const fullPathname = pathname + search;
      const paramPathname = `?link=${encodeURIComponent(fullPathname)}`;

      // If token is not present, redirect to login page
      // Adding query params to the redirecting link
      router.push(`/login${paramPathname}`);
    } else if (isToken && isAuthRoute) {
      // If user is logged in and tries to open auth routes
      // redirect him to home page
      router.push(dashboard);
    } else {
      setAuthorized(true);
    }
  }

  return authorized && children;
}

export default RouteGuard;
